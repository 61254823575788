import React, { useState, useEffect } from 'react';
import { registerReturn, getReturns } from '../services/returnsService';

const Returns = () => {
  const [returnData, setReturnData] = useState({ id_prestamo: '', fecha_devolucion: '' });
  const [returnsList, setReturnsList] = useState([]);

  // Función para cargar la lista de devoluciones
  const loadReturns = async () => {
    try {
      const response = await getReturns();
      setReturnsList(response.data);
    } catch (error) {
      console.error('Error al cargar las devoluciones:', error);
    }
  };

  // Llamar a loadReturns cuando el componente se monta
  useEffect(() => {
    loadReturns();
  }, []);

  const handleRegisterReturn = async () => {
    try {
      await registerReturn(returnData);
      alert('Devolución registrada con éxito');
      setReturnData({ id_prestamo: '', fecha_devolucion: '' });
      loadReturns();  // Actualizar la lista después de registrar una devolución
    } catch (error) {
      console.error('Error registrando devolución:', error);
    }
  };

  return (
    <div>
      <h2>Registrar Devolución</h2>
      <input
        type="text"
        placeholder="ID Préstamo"
        value={returnData.id_prestamo}
        onChange={(e) => setReturnData({ ...returnData, id_prestamo: e.target.value })}
      />
      <input
        type="date"
        placeholder="Fecha de Devolución"
        value={returnData.fecha_devolucion}
        onChange={(e) => setReturnData({ ...returnData, fecha_devolucion: e.target.value })}
      />
      <button onClick={handleRegisterReturn}>Registrar Devolución</button>

      {/* Lista de devoluciones */}
      <h3>Lista de Devoluciones</h3>
      <ul>
        {returnsList.map((returnItem) => (
          <li key={returnItem.id_prestamo}>{`Préstamo ID: ${returnItem.id_prestamo}, Fecha Devolución: ${returnItem.fecha_devolucion}`}</li>
        ))}
      </ul>
    </div>
  );
};

export default Returns;

