// frontend/src/services/historyServices.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_PROD === 'true' 
    ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_LOCAL;

// Crear instancia de axios con configuración base
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Interceptor para agregar token
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Servicio para obtener el historial
const getHistory = async () => {
    try {
        const response = await axiosInstance.get('/history');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Error al obtener el historial');
    }
};

// Exportar los servicios
export const historyService = {
    getHistory
};

export default historyService;