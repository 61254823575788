//frontend/src/App.js
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBook, faUndoAlt, faHistory, faCog, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import Books from "./components/Books";
import Loans from "./components/Loans";
import Returns from "./components/Returns";
import History from "./components/History";
import Login from "./components/Login";
import Ajustes from "./components/Ajustes";
import "./App.css";

const API_URL =
  process.env.REACT_APP_PROD === "true" 
  ? process.env.REACT_APP_API_URL_PROD 
  : process.env.REACT_APP_API_URL_LOCAL;

const isDev = process.env.NODE_ENV === 'development';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current) return;

    const verificarToken = async () => {
      if (isDev) console.log('🔍 Verificando token...');
      
      const token = localStorage.getItem("token");
      if (!token) {
        if (isDev) console.log('❌ No hay token almacenado');
        setIsAuthenticated(false);
        return;
      }

      try {
        if (isDev) console.log('🔑 Token encontrado, verificando...');
        await axios.get(`${API_URL}/protected`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (isDev) console.log('✅ Token válido');
        setIsAuthenticated(true);
      } catch (err) {
        if (isDev) console.log('❌ Token inválido o expirado:', err.message);
        setIsAuthenticated(false);
        localStorage.removeItem("token");
      }
    };

    verificarToken();
    effectRan.current = true;
  }, []); // Array de dependencias vacío

  // Función para cerrar sesión
  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <div>
      {isAuthenticated && (
        <nav>
          <ul>
              <nav>
                <ul>
                  <li>
                    <Link to="/books"><FontAwesomeIcon icon={faHome} /> Inicio</Link>
                  </li>
                  <li>
                    <Link to="/loans"><FontAwesomeIcon icon={faBook} /> Préstamos</Link>
                  </li>
                  <li>
                    <Link to="/returns"><FontAwesomeIcon icon={faUndoAlt} /> Devoluciones</Link>
                  </li>
                  <li>
                    <Link to="/history"> <FontAwesomeIcon icon={faHistory} /> Historial</Link>
                  </li>
                  <li>
                    <Link to="/ajustes"><FontAwesomeIcon icon={faCog} /> Ajustes</Link>
                  </li>
                  <li>
                    <button onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt} /> Cerrar Sesión</button>
                  </li>
                </ul>
              </nav>
            </ul>
          </nav>
        )}

        <Routes>
          {/* Ruta de Login */}
          <Route path="/login" element={<Login setAuth={setIsAuthenticated} />} />

          {/* Rutas Protegidas */}
          <Route path="/books" element={isAuthenticated ? <Books /> : <Navigate to="/login" />} />
          <Route path="/loans" element={isAuthenticated ? <Loans /> : <Navigate to="/login" />} />
          <Route path="/returns" element={isAuthenticated ? <Returns /> : <Navigate to="/login" />} />
          <Route path="/history" element={isAuthenticated ? <History /> : <Navigate to="/login" />} />
          <Route path="/ajustes" element={isAuthenticated ? <Ajustes /> : <Navigate to="/login" />} />

          {/* Redireccionar a /login si no está autenticado */}
          <Route path="*" element={<Navigate to={isAuthenticated ? "/books" : "/login"} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
