// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .history-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  }
  
  .history-table th,
  .history-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .history-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .history-table tr:hover {
    background-color: #f5f5f5;
  }
  
  .status-badge {
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.85em;
    font-weight: 500;
  }
  
  .status-borrowed {
    background-color: #e3f2fd;
    color: #1976d2;
  }
  
  .status-returned {
    background-color: #e8f5e9;
    color: #2e7d32;
  }
  
  .status-late {
    background-color: #ffebee;
    color: #c62828;
  }
  
  .history-container h2 {
    color: #333;
    margin-bottom: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/History.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,qCAAqC;EACvC;;EAEA;;IAEE,kBAAkB;IAClB,gBAAgB;IAChB,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB","sourcesContent":[".history-container {\n    padding: 20px;\n    max-width: 1200px;\n    margin: 0 auto;\n  }\n  \n  .history-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n    background: white;\n    box-shadow: 0 1px 3px rgba(0,0,0,0.2);\n  }\n  \n  .history-table th,\n  .history-table td {\n    padding: 12px 15px;\n    text-align: left;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  .history-table th {\n    background-color: #f4f4f4;\n    font-weight: bold;\n  }\n  \n  .history-table tr:hover {\n    background-color: #f5f5f5;\n  }\n  \n  .status-badge {\n    padding: 5px 10px;\n    border-radius: 15px;\n    font-size: 0.85em;\n    font-weight: 500;\n  }\n  \n  .status-borrowed {\n    background-color: #e3f2fd;\n    color: #1976d2;\n  }\n  \n  .status-returned {\n    background-color: #e8f5e9;\n    color: #2e7d32;\n  }\n  \n  .status-late {\n    background-color: #ffebee;\n    color: #c62828;\n  }\n  \n  .history-container h2 {\n    color: #333;\n    margin-bottom: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
