import React, { useState, useEffect } from 'react';
import './History.css'; // Crearemos este archivo para los estilos
import historyService from '../services/historyService';

const History = () => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
      const response = await historyService.getHistory();
      setHistory(response.data);
    } catch (error) {
      console.error('Error al obtener historial:', error);
      // Aquí podrías manejar el error, por ejemplo mostrar un mensaje al usuario
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getStatusBadge = (estado) => {
    const statusClasses = {
      'PRESTADO': 'status-borrowed',
      'DEVUELTO': 'status-returned',
      'ATRASADO': 'status-late'
    };
    return statusClasses[estado] || '';
  };

  return (
    <div className="history-container">
      <h2>Historial de Préstamos</h2>
      <table className="history-table">
        <thead>
          <tr>
            <th>Libro</th>
            <th>Código Ejemplar</th>
            <th>Fecha Préstamo</th>
            <th>Fecha Devolución</th>
            <th>Fecha Devuelto</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {history.map((item) => (
            <tr key={item.id_prestamo}>
              <td>{item.titulo}</td>
              <td>{item.codigo_ejemplar}</td>
              <td>{formatDate(item.fecha_prestamo)}</td>
              <td>{formatDate(item.fecha_devolucion)}</td>
              <td>{item.fecha_devolucion_real ? formatDate(item.fecha_devolucion_real) : '-'}</td>
              <td>
                <span className={`status-badge ${getStatusBadge(item.estado_prestamo)}`}>
                  {item.estado_prestamo}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default History;
