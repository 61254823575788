// frontend/src/components/Loans.js
import React, { useState, useEffect } from 'react';
import { registerLoan, getLoans } from '../services/loansService';
import './Loans.css'

const Loans = () => {
  // Estado para gestionar los datos del préstamo y la lista de préstamos
  const [loan, setLoan] = useState({ id_ejemplar: '', id_usuario: '', fecha_prestamo: '' });
  const [loansList, setLoansList] = useState([]);

  // Función para cargar la lista de préstamos desde el backend
  const loadLoans = async () => {
    try {
      const { data } = await getLoans(); // Extraemos directamente 'data' de la respuesta
      setLoansList(data); // Actualiza la lista de préstamos
    } catch (error) {
      console.error('Error al cargar los préstamos:', error);
    }
  };

  // Cargar la lista de préstamos al montar el componente
  useEffect(() => {
    loadLoans();
  }, []);

  // Función para registrar un nuevo préstamo
  const handleRegisterLoan = async () => {
    if (!loan.id_ejemplar || !loan.id_usuario || !loan.fecha_prestamo) {
      alert('Por favor completa todos los campos');
      return;
    }

    const loanData = {
      id_ejemplar: parseInt(loan.id_ejemplar),
      id_usuario: parseInt(loan.id_usuario),
      fecha_prestamo: loan.fecha_prestamo,
    };

    try {
      await registerLoan(loanData); // No necesitas guardar la respuesta si no la vas a usar
      alert('Préstamo registrado con éxito');
      loadLoans(); // Recargar la lista de préstamos después de registrar
    } catch (error) {
      console.error('Error al registrar el préstamo:', error);
      alert('Error al registrar el préstamo');
    }
  };

  return (
    <div className="loans-container">
      <h1>Registrar Préstamo</h1>
      <input
        type="number"
        placeholder="ID Ejemplar"
        value={loan.id_ejemplar}
        onChange={(e) => setLoan({ ...loan, id_ejemplar: e.target.value })}
      />
      <input
        type="number"
        placeholder="ID Usuario"
        value={loan.id_usuario}
        onChange={(e) => setLoan({ ...loan, id_usuario: e.target.value })}
      />
      <input
        type="date"
        value={loan.fecha_prestamo}
        onChange={(e) => setLoan({ ...loan, fecha_prestamo: e.target.value })}
      />
      <button onClick={handleRegisterLoan}>Registrar Préstamo</button>

      <h2>Lista de Préstamos</h2>
      <table className="loans-table">
        <thead>
          <tr>
            <th>ID Ejemplar</th>
            <th>Nombre Libro</th>
            <th>Usuario</th>
            <th>DNI</th>
            <th>Grado Académico</th>
            <th>Sección</th>
            <th>Fecha Préstamo</th>
          </tr>
        </thead>
        <tbody>
          {loansList.map((loan) => (
            <tr key={loan.id_prestamo}>
              <td>{loan.id_ejemplar}</td>
              <td>{loan.nombre_libro}</td>
              <td>{loan.nombre_usuario} {loan.apellido_usuario}</td>
              <td>{loan.dni}</td>
              <td>{loan.grado_academico}</td>
              <td>{loan.seccion}</td>
              <td>{loan.fecha_prestamo}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Loans;