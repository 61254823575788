// frontend/src/components/Login.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const API_URL =
  process.env.REACT_APP_PROD === "true" 
  ? process.env.REACT_APP_API_URL_PROD 
  : process.env.REACT_APP_API_URL_LOCAL;

const Login = ({ setAuth }) => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Redirige si el usuario ya está autenticado
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/books");
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!identifier || !password) {
      setError("Por favor, completa todos los campos.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await axios.post(`${API_URL}/auth/login`, {
        correo: identifier,
        password: password,
      });

      console.log("Token recibido:", response.data.token);
      localStorage.setItem("token", response.data.token);
      setAuth(true);
      navigate("/books");
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setError("Usuario o contraseña incorrectos.");
      } else {
        setError("Error de conexión. Inténtalo de nuevo más tarde.");
      }
      console.error("Error en el login:", err.response ? err.response.data : err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form" aria-label="Formulario de inicio de sesión">
        <img src="/images/logo.jpeg" alt="logo" className="login-logo" />
        <h2 className="login-title">Inicio de Sesión</h2>
        <input
          type="text"
          placeholder="Correo o DNI"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          required
          className="login-input"
          aria-label="Campo de usuario o DNI"
          autoFocus
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="login-input"
          aria-label="Campo de contraseña"
        />
        <button type="submit" className="login-button" disabled={loading}>
          {loading ? "Cargando..." : "Ingresar"}
        </button>
        {error && <p className="login-error">{error}</p>}
      </form>
    </div>
  );
};

export default Login;